<template>
  <div class="container-fluid">
    <div class="card nw-card mt-2">
      <div class="card-header nw-card-header text-left">
        <h4>Survey Summary</h4>
      </div>
      <div class="card-body text-left">
        <div class="row">
          <div class="col-4 border-right">
            <h3>User Experience Survey</h3>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="control-label">Start Date</label>
                  <input class="form-control" v-model="startDate"  type="text"/>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="control-label">End Date</label>
                  <input class="form-control" v-model="endDate"  type="text"/>
                </div>
              </div>
            </div>
            <div class="text-center"><button class="btn nw-btn-outline-info">Refresh</button></div>
            <hr/>
            <a href="#">Download Results <i class="fa fa-file-excel-o"></i></a>
            <hr/>
            <label class="control-label">Questions</label>
            <ul class="list-group">
              <li class="list-group-item active" aria-current="true">Would you Recommend?</li>
              <li class="list-group-item">Are you Better?</li>
              <li class="list-group-item">What can we do better?</li>
            </ul>
          </div>

          <div class="col-8">
            <survey-chart :chart-data="data" :options="options"></survey-chart>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import SurveyChart from './SurveyChart'

export default {
  components: { SurveyChart},
  data () {
    return {
      startDate: '2022-01-01',
      endDate: '2022-02-01',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
      data: {
          labels: ['1','2','3','4','5','6','7','8','9','10'],
          datasets: [{
            label: "Would You Recommend?",
            data: [0,0,0,0,0,1,2,3,8,18],
            backgroundColor: [
              'rgba(0, 123, 255, 0.8)',
              'rgba(0, 123, 255, 0.8)',
              'rgba(0, 123, 255, 0.8)',
              'rgba(0, 123, 255, 0.8)',
              'rgba(0, 123, 255, 0.8)',
              'rgba(0, 123, 255, 0.8)',
              'rgba(0, 123, 255, 0.8)',
              'rgba(0, 123, 255, 0.8)',
              'rgba(0, 123, 255, 0.8)',
              'rgba(0, 123, 255, 0.8)']
          }]
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
